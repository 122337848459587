<template>
  <v-card-text>
    <v-row>
      <formData
        v-for="(formDataItem, formDataItemKey) in blockData"
        :formKey="formKey"
        :blockIndex="blockIndex"
        :blockReadonly="blockReadonly"
        :blockDisabled="blockDisabled"
        :formDataItemKey="formDataItemKey"
        :formDataItem="formDataItem"
        :key="formDataItemKey"
      ></formData>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  props: {
    formKey: String,
    blockIndex: Number,
    blockReadonly: Boolean,
    blockDisabled: Boolean,
    blockData: Object,
    formDataItemKey: String,
    formDataItem: Object,
  },
  components: {
    formData: () => import('@/components/form/formData.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>